<template>
    <div class="page-menu-box">
        <div class="page-menu-hide-box" />
        <div class="page-menu-block">
            <div class="page-menu menu">
                <transition name="menu-show">
                    <div
                        v-show="pageMenuOpen"
                        class="page-menu-body"
                    >
                        <div class="page-menu-title-item">
                            <img
                                :src="menuData.icon"
                                alt=""
                            >
                            <div class="page-menu-title">
                                {{ $t(menuData.title) }}
                            </div>
                        </div>

                        <div
                            v-if="menuData.button && menuData.button.text"
                            class="page-menu-list-btn"
                            @click="goToRouter(menuData.button.routerTo)"
                        >
                            {{ $t(menuData.button.text) }}
                        </div>
                        <div class="page-menu-content">
                            <div
                                v-for="(item, index) in menuData.items"
                                :key="index"
                                class="page-menu-item"
                            >
                                <div
                                    v-if="item.type == 'router' || item.type == 'link'"
                                    class="item-content"
                                >
                                    <router-link
                                        v-if="item.type == 'router'"
                                        :to="{ name: item.routeName,hash: item.hash}"
                                        class="item-link"
                                    >
                                        {{ $t(item.text) }}
                                    </router-link>
                                    <a
                                        v-if="item.type == 'link'"
                                        class="item-link"
                                        :href="item.link"
                                        :target="item.target"
                                    >{{ $t(item.text) }}</a>
                                </div>

                                <v-expansion-panels
                                    v-if="item.type == 'panel'"
                                    accordion
                                >
                                    <v-expansion-panel class="item-panel">
                                        <v-expansion-panel-header class="panel-title">
                                            {{ $t(item.text) }}
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content class="panel-content">
                                            <div
                                                v-for="(subItem, i) in item.blockItems"
                                                :key="i"
                                                class="panel-sub-item"
                                            >
                                                <router-link
                                                    v-if="subItem.type == 'router'"
                                                    :to="{
                                                        name: subItem.routerName,
                                                        hash: subItem.hash
                                                    }"
                                                    class="panel-sub-link"
                                                >
                                                    {{ $t(subItem.text) }}
                                                </router-link>
                                                <a
                                                    v-if="subItem.type == 'link'"
                                                    class="panel-sub-link"
                                                    :href="subItem.link"
                                                    :target="subItem.target"
                                                >{{ $t(subItem.text) }}</a>
                                            </div>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </div>
                        </div>
                    </div>
                </transition>
                <div
                    class="page-menu-btn"
                    @click="clickPageMenu"
                >
                    <v-icon
                        v-show="!pageMenuOpen"
                        class="icon"
                    >
                        mdi-chevron-down
                    </v-icon>
                    <v-icon
                        v-show="pageMenuOpen"
                        class="icon"
                    >
                        mdi-chevron-up
                    </v-icon>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        menuData: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            pageMenuOpen: true,
        };
    },
    computed: {},
    watch: {
    // eslint-disable-next-line no-unused-vars
        $route(to, from) {
            const windowWidth = window.innerWidth;
            if (windowWidth <= 1024) {
                this.pageMenuOpen = false;
            }
        },
    },
    created() {
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.handleScroll();
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        goToRouter(router) {
            const language = this.$route.path.split('/')[1];
            if (language === 'zhTw' || language === 'en') {
                this.$router.push(`/${language}${router}`);
            }
            this.$router.push(`/zhTw${router}`);
        },
        clickPageMenu() {
            this.pageMenuOpen = !this.pageMenuOpen;
        },
        handleScroll() {
            const { scrollY } = window;
            this.handlePageMenu(scrollY);
        },
        handlePageMenu(scrollY) {
            const pageMenu = document.getElementsByClassName('page-menu')[0];
            const banner = document.getElementsByClassName('page-banner')[0];
            const menuHideBox = document.getElementsByClassName('page-menu-hide-box')[0];
            const viewWidth = window.innerWidth;
            if (!pageMenu) {
                return;
            }

            let bannerHeight = 0;
            if (banner) {
                bannerHeight = banner.offsetHeight;
            }

            if (scrollY > bannerHeight) {
                pageMenu.style.position = 'fixed';
                pageMenu.style.bottom = 'initial';
                pageMenu.style.top = '180px';
                menuHideBox.style.display = 'block';
                pageMenu.style.height = 'auto';
            } else {
                pageMenu.style.position = 'relative';
                menuHideBox.style.display = 'none';
                pageMenu.style.bottom = 'initial';
                pageMenu.style.top = 'initial';
                pageMenu.style.height = '100%';
            }

            if (viewWidth <= 1024) {
                pageMenu.style.height = 'auto';
            }
        },
    },
};
</script>

<style lang="scss">
$width: 240px;
.page-menu-box {
  .panel-title {
    display: inline-block;
    position: relative;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.5;
    .v-expansion-panel-header__icon {
        top: 50%;
        transform: translateY(calc(-50% + 4px));
    }
  }

  .page-menu-btn {
    display: none;
  }

  .page-menu-hide-box {
    width: $width;
    margin-right: 20px;
    flex-shrink: 0;
    display: none;
  }

  .page-menu {
    width: $width;
    height: 100%;
    flex-shrink: 0;
    margin-right: 20px;
    z-index: 10;
    position: relative;

    .page-menu-list-btn {
      background-color: $color-orange;
      border-radius: 2px;
      font-weight: 700;
      font-size: 16px;
      line-height: 1;
      letter-spacing: 0.1em;
      color: white;
      padding: 16px 24px;
      cursor: pointer;
    }

    .page-menu-body {
      background-color: white;
    }

    .page-menu-title-item {
      display: flex;
      align-items: center;
      padding-bottom: 14px;
      border-bottom: 2px solid $color-orange;
      margin-bottom: 7px;

      img {
        width: 18px;
        margin-right: 9px;
      }

      .page-menu-title {
        font-weight: 700;
        font-size: 18px;
        letter-spacing: 0.2em;
        color: $color-blue;
        line-height: 1;
      }
    }
    .page-menu-content {
      .page-menu-item {
        color: $color-blue;
        width: 100%;
        display: flex;
        align-items: center;
        border-bottom: 1px dashed $color-orange;
        cursor: pointer;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.1em;
        line-height: 1.5;
        text-decoration: none;

        .item-content{
          width: 100%;
        }

        .item-link {
          color: $color-blue;
          text-decoration: none;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          padding: 16px 24px;
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          line-height: 1.5;
        }

        .item-panel {
          width: 100%;
          height: 100%;
          color: $color-blue;
          box-shadow: none;
          border:none;

          .panel-content {
            display: flex;
            flex-direction: column;
          }

          .panel-sub-item {
            margin-bottom: 20px;
          }

          .panel-sub-link {
            display: block;
            font-weight: 700;
            font-size: 16px;
            line-height: 1;
            text-decoration: none;
            letter-spacing: 0.1em;
            color: $color-orange;
          }
          .panel-sub-item:last-child {
            margin-bottom: 0;
          }
        }
        .item-panel::before {
          box-shadow: none;
        }
      }

      .page-menu-item:hover {
        color: $color-orange;
        background-color: rgba(249, 126, 43, 0.2);
      }
    }
  }

  .menu-show-enter-active,
  .menu-show-leave-active {
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }

  .menu-show-enter,
  .menu-show-leave-to {
    opacity: 0;
  }
  .v-expansion-panel-header__icon{
    position: absolute;
    right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .page-menu-box {
    .page-menu-hide-box {
      display: none !important;
    }
    .page-menu {
      position: fixed !important;
      width: 100vw;
      top: 100px !important;
      left: 0 !important;
      bottom: initial !important;

      .page-menu-list-btn {
        padding: 15px 20px;
        text-align: center;
        margin-top: 25px;
      }

      .page-menu-title-item {
        justify-content: center;
        padding-top: 14px;
        padding-bottom: 0;
        border-bottom: none;
        margin-bottom: 0;
        background-color: white;
      }

      .page-menu-content {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-bottom: 1px solid $color-orange;

        .page-menu-item {
          border-bottom: 1px dashed $color-orange;
          display: flex;
          justify-content: flex-start;

          .panel-title {
            padding: 8px 24px;
          }

          .item-panel {
            .panel-content {
              .panel-sub-item {
                margin-right: 27px;
              }
              .panel-sub-link {
                display: inline-block;
              }
            }
          }
        }

        .page-menu-item:last-child {
          border: none;
        }
      }

      .page-menu-btn {
        width: 68px;
        height: 50px;
        background-color: white;
        border: 1px solid $color-orange;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 0 10px 10px;
        cursor: pointer;

        .icon {
          font-size: 40px;
          color: $color-orange;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .page-menu-box {
    .page-menu {
      position: fixed !important;
      width: 100vw;
      top: 100px !important;
      left: 0 !important;
      bottom: initial !important;

      .page-menu-title-item {
        justify-content: center;
        padding-top: 14px;
        padding-bottom: 0;
        border-bottom: none;
        margin-bottom: 0;
        background-color: white;
      }

      .page-menu-content {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-bottom: 1px solid $color-orange;
      }

      .page-menu-btn {
        width: 47px;
        height: 35px;

        .icon {
          font-size: 30px;
          color: $color-orange;
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .page-menu-box {
    .page-menu {
      .page-menu-title-item {
        img {
          width: 18px;
        }
        .page-menu-title {
          font-size: 18px;
        }
      }

      .page-menu-content {
        .page-menu-item {
          font-size: 14px;
          padding: 3px 4px;
        }
      }
    }
  }
}
</style>
